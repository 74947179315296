import React from "react";

function MedicalBells() {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="127"
            height="115"
            fill="none"
            viewBox="0 0 127 115"
        >
            <path
                stroke="#fff"
                strokeDasharray="2 2"
                d="M0.5 0.5H125.927V113.894H0.5z"
            ></path>
            <path
                fill="#fff"
                fillRule="evenodd"
                d="M82.22 34.318a3.94 3.94 0 00-1.15-2.781 3.94 3.94 0 00-2.782-1.151H46.83a3.932 3.932 0 00-3.932 3.932v43.256c0 1.489.841 2.85 2.173 3.517l5.005 2.503a3.941 3.941 0 003.517 0l5.232-2.617c.1-.05.218-.05.319 0l5.231 2.617a3.941 3.941 0 003.518 0l5.231-2.617c.1-.05.219-.05.319 0l3.087 1.544a3.933 3.933 0 005.69-3.517V34.318zM51.835 73.284h21.448a1.788 1.788 0 000-3.575H51.835a1.788 1.788 0 000 3.575zm0-7.15h21.448a1.788 1.788 0 000-3.575H51.835a1.788 1.788 0 000 3.575zm8.937-25.007a3.946 3.946 0 00-2.424 1.135 3.94 3.94 0 00-1.151 2.78v1.073a3.94 3.94 0 001.15 2.782 3.94 3.94 0 002.782 1.15h2.86c.095 0 .186.038.252.106a.353.353 0 01.105.252v1.073a.352.352 0 01-.105.252.353.353 0 01-.252.105h-5.005a1.788 1.788 0 000 3.575h1.788v1.787a1.788 1.788 0 003.574 0v-1.803a3.946 3.946 0 002.424-1.135 3.94 3.94 0 001.151-2.781v-1.073a3.94 3.94 0 00-1.15-2.78 3.94 3.94 0 00-2.782-1.152h-2.86a.353.353 0 01-.252-.105.353.353 0 01-.105-.253v-1.072c0-.094.037-.186.105-.252a.353.353 0 01.252-.105h5.005a1.788 1.788 0 000-3.575h-1.788v-1.788a1.788 1.788 0 00-3.574 0v1.804z"
                clipRule="evenodd"
            ></path>
        </svg>
    );
}

export default MedicalBells;
