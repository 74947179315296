import React from "react";

function PainSuffering() {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="127"
            height="115"
            fill="none"
            viewBox="0 0 127 115"
        >
            <path
                stroke="#fff"
                strokeDasharray="2 2"
                d="M0.823 0.5H126.25V113.894H0.823z"
            ></path>
            <path
                fill="#fff"
                d="M87.116 53.95l-6.069-6.65a5.586 5.586 0 01-.772-1.516c-2.04-7.874-7.999-13.59-15.17-15.398l-3.42 7.232c-.055.175 0 .35.11.409l4.8 3.558a.32.32 0 01.055.466l-5.517 6.474c-.11.117-.11.292 0 .467l3.145 3.616c.11.117.11.292.055.409l-5.351 8.34c-.166.292-.607.117-.552-.233l1.434-7.466c0-.117 0-.233-.11-.291l-4.358-4.317a.375.375 0 01-.055-.408l3.696-6.008c.11-.175.055-.35-.11-.466l-5.075-3.558c-.11-.058-.166-.233-.11-.35l1.654-7.816c-10.591 2.741-17.432 13.824-15.39 25.314 1.048 5.95 4.413 11.432 8.88 15.048 2.98 2.45 4.69 6.299 4.524 10.323l-.11 2.8c-.055 1.05.717 1.866 1.71 1.866h17.652c.938 0 1.655-.758 1.71-1.75l.276-9.448 7.116-1.4c1.876-.35 3.145-2.275 2.758-4.316l-1.655-9.39 2.703-.526c2.262-.408 3.09-3.266 1.545-5.016z"
            ></path>
        </svg>
    );
}

export default PainSuffering;
