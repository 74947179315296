import React from "react";

function LostEarningCapacity() {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="127"
            height="115"
            fill="none"
            viewBox="0 0 127 115"
        >
            <path
                stroke="#fff"
                strokeDasharray="2 2"
                d="M0.5 0.5H125.927V113.894H0.5z"
            ></path>
            <path
                fill="#fff"
                d="M49.24 47.303a8.717 8.717 0 00-1.879-2.233 11.645 11.645 0 00-2.33-1.522 14.056 14.056 0 00-2.521-.949 21.195 21.195 0 00-2.449-.51 19.765 19.765 0 00-2.115-.21 42.357 42.357 0 00-1.529-.036v6.617l-12.466-9.05 12.466-9.023v5.846c1.523.068 2.875.28 4.052.632 1.178.351 2.21.791 3.094 1.32a10.689 10.689 0 012.273 1.781c.627.658 1.15 1.323 1.565 1.996.977 1.583 1.59 3.365 1.838 5.34zM78.38 47.303a8.697 8.697 0 011.879-2.232 11.647 11.647 0 012.33-1.523c.826-.402 1.667-.72 2.523-.948a21.116 21.116 0 012.449-.51 19.739 19.739 0 012.113-.21 42.748 42.748 0 011.53-.037v6.617l12.465-9.051-12.464-9.023v5.846c-1.524.068-2.875.28-4.053.632-1.177.35-2.21.791-3.093 1.32a10.676 10.676 0 00-2.273 1.78 12.117 12.117 0 00-1.564 1.997c-.981 1.584-1.596 3.367-1.842 5.342zM64.029 56.61c-4.752 0-8.624-3.872-8.624-8.625 0-4.771 3.87-8.64 8.624-8.64 4.78 0 8.64 3.865 8.64 8.64 0 4.757-3.861 8.625-8.64 8.625z"
            ></path>
            <path
                fill="#fff"
                d="M82.536 85.577V72.016l4.981 13.561h8.28s-1.97-12.032-9.95-23.832c-4.05-5.564-11.545-7.027-16.177-4.023-.559.141-13.89 12.303-13.89 12.303l3.525-7.74a4.157 4.157 0 00-7.58-3.418l-8.917 19.785c-.79 1.75-.424 4.613 1.6 5.914 2.111 1.344 4.558.576 6.142-.671L60.773 75.7c-1.741 5.483-2.58 9.873-2.58 9.873h24.345l-.001.004z"
            ></path>
        </svg>
    );
}

export default LostEarningCapacity;
