import { ReactElement } from "react";
import style from "./style.module.scss";
import LostEarningCapacity from "../icons/LostEarningCapacity";
import Counseling from "../icons/Counseling";
import MedicalBells from "../icons/MedicalBells";
import PainSuffering from "../icons/PainSuffering";

const uberAccidentList = [
    {
        title: "Medical Treatment",

        icon: <MedicalBells />,
    },
    {
        title: "Counseling",

        icon: <Counseling />,
    },
    {
        title: "Pain & Suffering",

        icon: <PainSuffering />,
    },
    {
        title: "Lost Time or Earnings",

        icon: <LostEarningCapacity />,
    },
];

export default function UberAccidentSection(): ReactElement {
    return (
        <section className={`${style["mainSection"]} flex flex-col   md:pl-28`}>
            <h2 className="font-bold text-3xl leading-[45px] mt-20">
                Uber & Lyft Sexual Assault Lawsuits
            </h2>
            <p className="text-lg leading-8 mt-5 max-w-5xl">
                Uber and Lyft have been scrutinized for inadequate safety
                protocols in their driver recruitment, particularly regarding
                background checks and in-person interviews, with concerns
                amplified regarding female passenger safety. These companies
                have faced accusations of neglecting passenger complaints,
                mishandling sensitive information, and reassigning reported
                drivers. To address these issues, lawyers are now aiding victims
                in reporting incidents, obtaining support, and pursuing legal
                action for appropriate compensation for the damages incurred.
                You could be eligible for compensation for any of the following:
            </p>
            <div className="flex flex-col gap-10  sm:flex-row  sm:flex-wrap lg:justify-center lg:gap-24  lg:max-w-5xl       mt-12      mb-28">
                {uberAccidentList.map((el, index) => (
                    <div key={index} className="flex flex-col  items-center  ">
                        <span className="">{el.icon}</span>
                        <div className="text-lg leading-8 ">
                            <h3 className="my-3 max-w-[160px] mx-auto  text-center">
                                {el.title}
                            </h3>
                        </div>
                    </div>
                ))}
            </div>
        </section>
    );
}
