import React from "react";

function Counseling() {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="127"
            height="115"
            fill="none"
            viewBox="0 0 127 115"
        >
            <path
                stroke="#fff"
                strokeDasharray="2 2"
                d="M0.5 0.5H125.927V113.894H0.5z"
            ></path>
            <path
                fill="#fff"
                d="M51.111 68.898a1.831 1.831 0 00-1.274-.517H38.68V39.627a1.84 1.84 0 10-3.681 0v42.58a1.84 1.84 0 103.681 0V72.061h9.753v10.147a1.84 1.84 0 103.681 0v-11.68c0-.715-.41-1.326-1.004-1.63zM49.402 34.89a4.89 4.89 0 11-9.78-.001 4.89 4.89 0 019.78 0"
            ></path>
            <path
                fill="#fff"
                d="M56.771 59.37h-8.324V56.16a4.028 4.028 0 002.997-.83 4.048 4.048 0 00.687-5.686l-3.696-4.712a4.414 4.414 0 00-8.821.267v18.59a4.418 4.418 0 004.418 4.418h8.324v11.353a4.418 4.418 0 008.835 0V63.788a4.42 4.42 0 00-4.42-4.417zM89.902 37.787a1.84 1.84 0 00-1.841 1.84V68.38H76.906a1.84 1.84 0 100 3.681H88.06v10.147a1.84 1.84 0 103.682 0V39.627c0-1.016-.824-1.841-1.84-1.841zM87.118 34.89a4.89 4.89 0 11-9.778 0 4.89 4.89 0 019.778 0z"
            ></path>
            <path
                fill="#fff"
                d="M82.713 40.779a4.413 4.413 0 00-4.403 4.151l-3.697 4.712a4.048 4.048 0 003.684 6.515v3.212h-8.326a4.418 4.418 0 00-4.418 4.418v15.771a4.418 4.418 0 008.836 0V68.204h8.324a4.418 4.418 0 004.418-4.417v-18.59a4.42 4.42 0 00-4.418-4.418z"
            ></path>
        </svg>
    );
}

export default Counseling;
